export const proofSettingsActionTypes = {
  GET_PROOF_SETTINGS_START: 'GET_PROOF_SETTINGS_START',
  GET_PROOF_SETTINGS_SUCCESS: 'GET_PROOF_SETTINGS_SUCCESS',
  GET_PROOF_SETTINGS_FAILURE: 'GET_PROOF_SETTINGS_FAILURE',
  SAVE_PROOF_SETTINGS_START: 'SAVE_PROOF_SETTINGS_START',
  SAVE_PROOF_SETTINGS_SUCCESS: 'SAVE_PROOF_SETTINGS_SUCCESS',
  SAVE_PROOF_SETTINGS_FAILURE: 'SAVE_PROOF_SETTINGS_FAILURE',
  UPDATE_HAS_BEEN_EDITED: 'UPDATE_HAS_BEEN_EDITED',
  UPDATE_SETTINGS_VALUE: 'UPDATE_SETTINGS_VALUE',
  REVERT_CHANGES_TO_ORIGINAL_DATA: 'REVERT_CHANGES_TO_ORIGINAL_DATA',
};
