import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Localization } from '@wf-mfe/localize-react';
import { LabelDropdown } from '@phoenix/all';
import { updateSettingsValue } from '../redux/settings/settings.actions';
import { useDeadlineAtRiskOptions } from './deadline.hooks';

const DeadlineAtRiskLabel = () => {
  const dispatch = useDispatch();

  const {
    editedData: { deadlineAtRiskThreshold },
  } = useSelector((state) => state.settings);

  const dropdownOptions = useDeadlineAtRiskOptions();

  const selectedOption = dropdownOptions.find((option) => option.value === deadlineAtRiskThreshold);

  return (
    <>
      <Localization
        messageKey="setup.proof.settings.enabledeadlineatrisk.before"
        fallback="Notify recipients"
      />
      <LabelDropdown
        multiple={false}
        open={false}
        options={dropdownOptions}
        value={selectedOption}
        onChange={(selectedItem) =>
          dispatch(updateSettingsValue('deadlineAtRiskThreshold', selectedItem.value))
        }
        testID="deadline-at-risk-threshold-label-dropdown"
      />
      <Localization
        messageKey="setup.proof.settings.enabledeadlineatrisk.after"
        fallback="before a proof is at risk"
      />
    </>
  );
};

export default DeadlineAtRiskLabel;
