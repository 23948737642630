import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox, CheckboxGroup } from '@phoenix/all';
import { Localization } from '@wf-mfe/localize-react';
import { updateSettingsValue } from '../redux/settings/settings.actions';
import InlineCheckboxLabel from './inline-checkbox-label.component';

const SettingsEmail = () => {
  const dispatch = useDispatch();
  const {
    editedData: { notifyRecipients },
  } = useSelector((state) => state.settings);

  return (
    <CheckboxGroup
      legend={
        <Localization
          messageKey="setup.proof.settings.emailnotifications"
          fallback="Email notifications"
        />
      }
    >
      <InlineCheckboxLabel>
        <Checkbox
          name="notifyRecipients"
          label={
            <Localization
              messageKey="setup.proof.settings.emailnotificationsenabled"
              fallback="Disable proof email notifications"
            />
          }
          checked={!notifyRecipients}
          onChange={(event) =>
            dispatch(updateSettingsValue('notifyRecipients', !event.target.checked))
          }
          testID="settings-email-notify-recipients-checkbox"
        />
      </InlineCheckboxLabel>
    </CheckboxGroup>
  );
};

export default SettingsEmail;
