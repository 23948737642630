import React from 'react';
import { Localization } from '@wf-mfe/localize-react';
import { Card, H3 } from '@phoenix/all';
import { css } from 'emotion';
import { useSelector } from 'react-redux';
import SettingsDescription from './description.component';
import SettingsRecipients from './recipients.component';
import SettingsDeadline from './deadline.component';
import SettingsEmail from './email.component';
import SettingsDecisionsContainer from './decisions-container.component';
import { PageSectionHeader, PageContent } from '../styled';

const inputBlockerAndFade = css`
  transition-duration: 100ms;
  transition-property: opacity;
`;
const enabledStyles = css`
  ${inputBlockerAndFade}
  transition-delay: 0s;
  opacity: 1;
`;
const disabledStyles = css`
  ${inputBlockerAndFade}
  transition-delay: 1s;
  opacity: 0.2;
  pointer-events: none;
`;

const insetCard = css`
  padding-left: 8px;
  padding-right: 8px;
`;

const SettingsContent = () => {
  const { isLoaded } = useSelector((state) => state.settings);
  return (
    <div className={`${isLoaded ? enabledStyles : disabledStyles}`}>
      <PageContent>
        <SettingsDescription />
        <Card>
          <div className={insetCard}>
            <SettingsRecipients />
            <SettingsDeadline />
            <SettingsEmail />
          </div>
        </Card>
        <PageSectionHeader>
          <H3>
            <Localization messageKey="setup.proof.settings.decisions" fallback="Decisions" />
          </H3>
        </PageSectionHeader>
        <Card>
          <div className={insetCard}>
            <SettingsDecisionsContainer />
          </div>
        </Card>
      </PageContent>
    </div>
  );
};

export default SettingsContent;
