import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox, FormGroup } from '@phoenix/all';
import { Localization } from '@wf-mfe/localize-react';
import { updateSettingsValue } from '../redux/settings/settings.actions';
import LineBreak from '../common/line-break.component';
import InlineCheckboxLabel from './inline-checkbox-label.component';
import DefaultDeadline from './default-deadline.component';
import DeadlineAtRiskLabel from './deadline-at-risk-label.component';

const SettingsDeadline = () => {
  const dispatch = useDispatch();
  const {
    editedData: { enableDeadlineAtRiskNotifications },
  } = useSelector((state) => state.settings);

  return (
    <>
      <FormGroup
        legend={<Localization messageKey="setup.proof.settings.deadline" fallback="Deadline" />}
      >
        <DefaultDeadline />
        <InlineCheckboxLabel>
          <Checkbox
            name="enableDeadlineAtRiskNotifications"
            label={<DeadlineAtRiskLabel />}
            checked={enableDeadlineAtRiskNotifications}
            onChange={(event) => {
              const {
                target: { checked },
              } = event;

              dispatch(updateSettingsValue('enableDeadlineAtRiskNotifications', checked));
            }}
            testID="settings-deadline-at-risk-checkbox"
          />
        </InlineCheckboxLabel>
      </FormGroup>
      <LineBreak />
    </>
  );
};

export default SettingsDeadline;
