import React, { useEffect } from 'react';
import { ScrollLayout } from '@setup/components';
import { useDispatch, useSelector } from 'react-redux';
import SettingsHeader from './header.component';
import SettingsFooter from './footer.component';
import { getSettings } from '../redux/settings/settings.actions';
import SettingsContent from './content.component';
import { Page } from '../styled';

const SettingsPage = () => {
  const dispatch = useDispatch();
  const { showErrorPage, error } = useSelector((state) => state.settings);

  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  if (showErrorPage) {
    // throwing an error trigger error boundary.This also will log to datadog.
    throw new Error(`Error: failed to get proof settings: ${error}`);
  }

  return (
    <Page>
      <ScrollLayout
        header={<SettingsHeader />}
        footer={<SettingsFooter />}
        stickyHeader
        stickyFooter
      >
        <SettingsContent />
      </ScrollLayout>
    </Page>
  );
};

export default SettingsPage;
