import React from 'react';
import { addToast as phoenixAddToast } from '@phoenix/all';
import { LocalizationProvider } from '@wf-mfe/localize-react';

const localizedToast = (type, localizedComponent, title) => {
  const wrapInProvider = (component) => (
    <LocalizationProvider clientFactory={() => ({ namespace: 'redrock' })}>
      {component}
    </LocalizationProvider>
  );

  phoenixAddToast(type, wrapInProvider(localizedComponent), {
    toastTitle: type === 'error' ? wrapInProvider(title) : '',
    duration: type === 'error' ? 5 : 3,
  });
};

export default localizedToast;
