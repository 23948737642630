import React from 'react';
import { Checkbox, CheckboxGroup, Textarea, Tooltip } from '@phoenix/all';
import { Localization } from '@wf-mfe/localize-react';
import InfoSmallIcon from 'phoenix-icons/dist/InfoSmallIcon.js';
import { useSelector, useDispatch } from 'react-redux';
import { css } from 'emotion';
import { updateSettingsValue } from '../redux/settings/settings.actions';
import LineBreak from '../common/line-break.component';
import InlineCheckboxLabel from './inline-checkbox-label.component';
import SettingsCustomMessage from './custom-message.component';

export const recipientInfoTooltip = css`
  svg {
    margin-left: 5px;
    display: flex;
    align-items: center;
  }
`;

const textareaFor = css`
  padding-bottom: 0px;
`;

const SettingsRecipients = () => {
  const dispatch = useDispatch();
  const {
    editedData: {
      requireLogin,
      allowCopyOwner,
      allowCommentDeletion,
      requireDecisionSignature,
      signatureMessage,
    },
  } = useSelector((state) => state.settings);

  return (
    <>
      <CheckboxGroup
        className="checkboxtolookfor"
        legend={<Localization messageKey="setup.proof.settings.recipients" fallback="Recipients" />}
      >
        <InlineCheckboxLabel>
          <Checkbox
            name="requireLogin"
            label={
              <Localization
                messageKey="setup.proof.settings.requirelogin"
                fallback="Require login"
              />
            }
            checked={requireLogin}
            onChange={(event) =>
              dispatch(updateSettingsValue('requireLogin', event.target.checked))
            }
            testID="settings-recipients-require-login-checkbox"
          />
          <Tooltip
            className={recipientInfoTooltip}
            content={
              <Localization
                messageKey="setup.proof.settings.nouseroverride"
                fallback="Users can't override this setting"
              />
            }
            icon={<InfoSmallIcon />}
          />
        </InlineCheckboxLabel>
        <InlineCheckboxLabel>
          <Checkbox
            name="allowCopyOwner"
            label={
              <Localization
                messageKey="setup.proof.settings.copyowner"
                fallback="Copy owner from original proof for new versions"
              />
            }
            checked={allowCopyOwner}
            onChange={(event) =>
              dispatch(updateSettingsValue('allowCopyOwner', event.target.checked))
            }
            testID="settings-recipients-allow-copy-owner-checkbox"
          />
        </InlineCheckboxLabel>
        <InlineCheckboxLabel>
          <Checkbox
            name="allowCommentDeletion"
            label={
              <Localization
                messageKey="setup.proof.settings.allowdeletion"
                fallback="Allow users to delete their proof comments"
              />
            }
            checked={allowCommentDeletion}
            onChange={(event) =>
              dispatch(updateSettingsValue('allowCommentDeletion', event.target.checked))
            }
            testID="settings-recipients-allow-comment-deletion-checkbox"
          />
        </InlineCheckboxLabel>
        <InlineCheckboxLabel
          className={css`
            padding-bottom: 4px;
          `}
        >
          <Checkbox
            name="requireDecisionSignature"
            label={
              <Localization
                messageKey="setup.proof.settings.requiredecisionmessage"
                fallback="Require decisions to be electronically signed"
              />
            }
            checked={requireDecisionSignature}
            onChange={(event) =>
              dispatch(updateSettingsValue('requireDecisionSignature', event.target.checked))
            }
            testID="settings-recipients-require-decision-signature-checkbox"
          />
          <Tooltip
            className={recipientInfoTooltip}
            content={
              <Localization
                messageKey="setup.proof.settings.nouseroverride"
                fallback="Users can't override this setting"
              />
            }
            icon={<InfoSmallIcon />}
          />
        </InlineCheckboxLabel>
        {requireDecisionSignature ? (
          <SettingsCustomMessage>
            <Textarea
              label={
                <Localization
                  messageKey="setup.proof.settings.decisionmessage"
                  fallback="Create a custom message. This message displays as the user is signing the decision."
                />
              }
              className={textareaFor}
              name="signatureMessage"
              value={signatureMessage}
              onChange={(event) => {
                dispatch(updateSettingsValue('signatureMessage', event.target.value));
              }}
              testID="settings-recipients-signature-message-input"
            />
          </SettingsCustomMessage>
        ) : null}
      </CheckboxGroup>
      <LineBreak />
    </>
  );
};

export default SettingsRecipients;
