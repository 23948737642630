import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';

const contentStyles = css`
  padding-left: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
`;

const contentContatinerStyles = css`
  margin-right: 24px;
  max-width: 732px;
  min-width: 680px;
`;

const PageContent = (props) => {
  const { children } = props;

  return (
    <div className={contentStyles}>
      <div className={contentContatinerStyles}>{children}</div>
    </div>
  );
};

PageContent.propTypes = {
  children: PropTypes.node,
};

export default PageContent;
