import React, { useState, useEffect } from 'react';
import { css } from 'emotion';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensors,
  useSensor,
  MeasuringStrategy,
  PointerSensor,
  DragOverlay,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Text, primary } from '@phoenix/all';
import { useSelector, useDispatch } from 'react-redux';
import SortableSettingsDecisionInput from './decision-input.component';
import SettingsDecisionsInfo from './decisions-info.component';
import { updateSettingsValue } from '../redux/settings/settings.actions';

const decisionsContainerStyles = css`
  display: flex;
  margin-top: 16px;
  flex-direction: column;
`;

const gridStyles = css`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  justify-content: center;
  gap: 16px;
`;

const defaultLabelStyles = css`
  text-align: center;
  text-transform: uppercase;
  color: ${primary.blue()};
  display: flex;
  justify-content: center;
  align-items: end;
  margin-bottom: 4px;
`;

const SettingsDecisionsContainer = () => {
  const dispatch = useDispatch();

  const {
    editedData: { decisions },
  } = useSelector((state) => state.settings);

  const [showDefaults, setShowDefaults] = useState(false);
  const [canRestore, setCanRestore] = useState(false);
  const [filteredDecisions, setFilteredDecisions] = useState([]);
  const [activeDecision, setActiveDecision] = useState(null);

  useEffect(() => {
    setFilteredDecisions(
      decisions?.filter((decision) => {
        return !decision.defaultLabel.match(/^[Pp]ending$/);
      })
    );
  }, [decisions]);

  useEffect(() => {
    if (decisions) {
      let isCustom = false;
      let isRestorable = false;
      decisions?.forEach((decision) => {
        if (decision.label !== decision.defaultLabel) {
          isCustom = true;
        }
        if (decision.position !== decision.defaultPosition || !decision.enabled) {
          isRestorable = isCustom || true;
        }
      });
      setShowDefaults(isCustom);
      setCanRestore(isRestorable);
    }
  }, [decisions]);

  const handleDragEnd = (event) => {
    const {
      active: {
        id: activeId,
        data: {
          current: {
            sortable: { index: activeIndex },
          },
        },
      },
      over: {
        id: overId,
        data: {
          current: {
            sortable: { index: overIndex },
          },
        },
      },
    } = event;

    if (activeId !== overId) {
      const newDecisions = arrayMove(filteredDecisions, activeIndex, overIndex);

      const pendingIndex = decisions.findIndex((decision) => decision.defaultLabel === 'Pending');
      newDecisions.push(decisions[pendingIndex]);

      const newArray = newDecisions.map((decision, idx) => {
        return { ...decision, position: idx };
      });

      dispatch(updateSettingsValue('decisions', newArray));
      setCanRestore(true);
      setActiveDecision(null);
    }
  };

  const handleDragStart = ({ active: { id } }) => {
    const activeDecisionIndex = decisions.findIndex((decision) => decision.defaultLabel === id);
    setActiveDecision(decisions[activeDecisionIndex]);
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(PointerSensor)
  );

  if (!filteredDecisions) {
    return null;
  }

  return (
    <>
      <SettingsDecisionsInfo canRestore={canRestore || showDefaults} />
      <DndContext
        sensors={sensors}
        measuring={{
          droppable: {
            strategy: MeasuringStrategy.Always,
          },
        }}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        collisionDetection={closestCenter}
      >
        <SortableContext
          items={filteredDecisions.map((decision) => decision.defaultLabel)}
          strategy={horizontalListSortingStrategy}
        >
          <div className={decisionsContainerStyles}>
            <div className={gridStyles}>
              {showDefaults &&
                filteredDecisions.map((decision) => {
                  return (
                    <Text.Small
                      className={defaultLabelStyles}
                      data-testid="decision-input-default-label-text"
                      key={decision.position}
                    >
                      {
                        // these strings are localized on the backend
                        decision.defaultLabel
                      }
                    </Text.Small>
                  );
                })}
            </div>
            <div className={gridStyles}>
              {filteredDecisions.map((decision) => {
                return (
                  <SortableSettingsDecisionInput
                    decision={decision}
                    showDefaults={showDefaults}
                    key={decision.defaultLabel}
                  />
                );
              })}
            </div>
            <DragOverlay dropAnimation={null}>
              {activeDecision ? (
                <SortableSettingsDecisionInput
                  decision={activeDecision}
                  showDefaults={showDefaults}
                  key={activeDecision.defaultLabel}
                  isActive
                />
              ) : null}
            </DragOverlay>
          </div>
        </SortableContext>
      </DndContext>
    </>
  );
};

export default SettingsDecisionsContainer;
