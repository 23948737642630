import isMatch from 'lodash/isMatch';
import { proofSettingsActionTypes as types } from './settings.types';

export const initialState = {
  originalData: {
    // this is what the data looks like coming back
    //
    /*
    accountId: 1,
    allowCommentDeletion: true,
    allowCopyOwner: true,
    commentActions: [],
    deadlineAtRiskThreshold: 24,
    decisions: [],
    defaultDeadline: 0,
    enableDeadlineAtRiskNotifications: false,
    id: 1,
    notifyRecipients: true,
    requireDecisionSignature: false,
    requireLogin: false,
    signatureMessage: '',
    */
  },
  editedData: {
    // this is a duplicated of to compare if fields have changed
  },

  isLoaded: false,
  error: '',
  hasBeenEdited: false,
  showErrorPage: false,
  hasBeenSaved: false,
};

const settingsReducer = (state, action) => {
  let editedData;
  let hasBeenEdited;

  if (!state) {
    state = initialState;
  }

  switch (action.type) {
    // Get Settings //
    case types.GET_PROOF_SETTINGS_START:
      return { ...state, isLoaded: false, hasBeenEdited: false };
    case types.GET_PROOF_SETTINGS_SUCCESS:
      return { ...state, ...action.payload, isLoaded: true, hasBeenSaved: false };
    case types.GET_PROOF_SETTINGS_FAILURE:
      return { ...state, originalData: {}, editedData: {}, isLoaded: true, ...action.payload };
    // Save Settings //
    case types.SAVE_PROOF_SETTINGS_START:
      return { ...state, isLoaded: false, hasBeenEdited: false };
    case types.SAVE_PROOF_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoaded: true,
        hasBeenEdited: false,
        hasBeenSaved: true,
      };
    case types.SAVE_PROOF_SETTINGS_FAILURE:
      return {
        ...state,
        isLoaded: true,
        hasBeenEdited: true,
        ...action.payload,
        hasBeenSaved: false,
      };
    // Update Settings //
    case types.UPDATE_SETTINGS_VALUE:
      editedData = { ...state.editedData, ...action.payload };
      hasBeenEdited = !isMatch(state.originalData, editedData);
      return { ...state, editedData, hasBeenEdited, hasBeenSaved: false };
    // Undo Edits
    case types.REVERT_CHANGES_TO_ORIGINAL_DATA:
      return { ...state, editedData: state.originalData, hasBeenEdited: false };
    default:
      return state;
  }
};

export default settingsReducer;
