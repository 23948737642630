import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { css } from 'emotion';
import { configureAppStore } from './redux/store';
import SettingsPage from './settings/page.component';
import { ErrorBoundary } from './logger.js';

const wfMFEProofSetupStyles = css`
  // calc -2px removing extra padding causing scroll bar
  height: calc(100% - 2px);
`;

const store = configureAppStore();

export const ProofAccountSettings = () => {
  return (
    <div className={wfMFEProofSetupStyles}>
      <ErrorBoundary>
        <ReduxProvider store={store}>
          <SettingsPage />
        </ReduxProvider>
      </ErrorBoundary>
    </div>
  );
};
