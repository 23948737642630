import React from 'react';
import { css } from 'emotion';

const LineBreakStyles = css`
  height: 1px;
  left: 305px;
  top: 483px;

  border-top: 1px solid rgb(193, 195, 199);
  margin-bottom: 16px;
`;

const LineBreak = () => {
  return <div data-testid="linebreak" className={LineBreakStyles} />;
};

export default LineBreak;
