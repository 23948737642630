import React from 'react';
import { Localization } from '@wf-mfe/localize-react';
import { H3, Text } from '@phoenix/all';
import { css } from 'emotion';

const SettingsParentStyles = css`
  padding-bottom: 16px;
`;

const SettingsDescription = () => {
  return (
    <div className={SettingsParentStyles}>
      <H3
        className={css`
          padding: 0 0 8px;
        `}
      >
        <Localization messageKey="setup.proof.settings.title" fallback="New proof defaults" />
      </H3>
      <Text.Small>
        <Localization
          messageKey="setup.proof.settings.description"
          fallback="These settings allow you to set default values that apply to all proofs created by your users. Users can override most of these settings when creating a proof."
        />
      </Text.Small>
    </div>
  );
};

export default SettingsDescription;
