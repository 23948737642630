import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './root.reducer';

export const configureAppStore = (preloadedState) => {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: process.env.NODE_ENV !== 'production' ? { name: 'Proof Setup' } : null,
  });

  return store;
};
