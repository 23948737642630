import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';

const inlineCheckboxLabelStyles = css`
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-bottom: 12px;

  &:last-child {
    padding-bottom: 8px;
  }

  div {
    margin-left: 4px;
    color: #0d66d0;
    display: flex;
    align-items: center;

    svg {
      margin-left: 2px;
    }
  }

  label {
    font-size: 14px;
    color: #303743;
    margin-bottom: 0px;

    span {
      display: flex;
      align-items: center;
    }
  }
`;

const InlineCheckboxLabel = (props) => {
  return (
    <div
      data-testid="in-line-checkbox-label"
      className={css`
        ${inlineCheckboxLabelStyles} ${props.className}
      `}
    >
      {props.children}
    </div>
  );
};

InlineCheckboxLabel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default InlineCheckboxLabel;
