import React from 'react';
import { Button } from '@phoenix/all';
import { Localization } from '@wf-mfe/localize-react';
import { useSelector, useDispatch } from 'react-redux';
import Footer from '../common/footer.component';
import { saveSettings, revertChangesToOriginalData } from '../redux/settings/settings.actions';

const SettingsFooter = () => {
  const dispatch = useDispatch();
  const { hasBeenEdited, hasBeenSaved, isLoaded } = useSelector((state) => state.settings);

  const handleSave = () => {
    dispatch(saveSettings());
  };
  const handleCancel = () => {
    dispatch(revertChangesToOriginalData());
  };

  const saveButtonText = () => {
    switch (true) {
      case hasBeenSaved && !hasBeenEdited && isLoaded:
        return <Localization messageKey="saved" fallback="Saved" />;
      case !isLoaded:
        return <Localization messageKey="action.saving" fallback="Saving..." />;
      default:
        return <Localization messageKey="action.save" fallback="Save" />;
    }
  };

  return (
    <Footer>
      <Button data-testid="save" onClick={handleSave} disabled={!hasBeenEdited}>
        {saveButtonText()}
      </Button>
      {hasBeenEdited ? (
        <Button data-testid="cancel" onClick={handleCancel} disabled={!hasBeenEdited} text>
          <Localization messageKey="action.cancel" fallback="Cancel" />
        </Button>
      ) : null}
    </Footer>
  );
};

export default SettingsFooter;
