import { useLocalizations } from '@wf-mfe/localize-react';

const deadlineOptionLocalizations = {
  none: {
    messageKey: 'setup.proof.settings.none',
    fallback: 'None',
  },
  minutes15: {
    messageKey: 'setup.proof.settings.minutes15',
    fallback: '15 minutes',
  },
  businessday1: {
    messageKey: 'setup.proof.settings.businessday1',
    fallback: '1 business day',
  },
  businessday2: {
    messageKey: 'setup.proof.settings.businessday2',
    fallback: '2 business days',
  },
  businessday3: {
    messageKey: 'setup.proof.settings.businessday3',
    fallback: '3 business days',
  },
  businessday4: {
    messageKey: 'setup.proof.settings.businessday4',
    fallback: '4 business days',
  },
  businessday5: {
    messageKey: 'setup.proof.settings.businessday5',
    fallback: '5 business days',
  },
  businessday6: {
    messageKey: 'setup.proof.settings.businessday6',
    fallback: '6 business days',
  },
  businessday7: {
    messageKey: 'setup.proof.settings.businessday7',
    fallback: '7 business days',
  },
  businessday8: {
    messageKey: 'setup.proof.settings.businessday8',
    fallback: '8 business days',
  },
  businessday9: {
    messageKey: 'setup.proof.settings.businessday9',
    fallback: '9 business days',
  },
  businessday10: {
    messageKey: 'setup.proof.settings.businessday10',
    fallback: '10 business days',
  },
};

const selectedDeadlineOptionLocalizations = {
  none: {
    messageKey: 'setup.proof.settings.noneselected',
    fallback: '__ days',
  },
  minutes15: {
    messageKey: 'setup.proof.settings.minutes15',
    fallback: '15 mins',
  },
  day1: {
    messageKey: 'setup.proof.settings.day1',
    fallback: '1 day',
  },
  day2: {
    messageKey: 'setup.proof.settings.day2',
    fallback: '2 days',
  },
  day3: {
    messageKey: 'setup.proof.settings.day3',
    fallback: '3 days',
  },
  day4: {
    messageKey: 'setup.proof.settings.day4',
    fallback: '4 days',
  },
  day5: {
    messageKey: 'setup.proof.settings.day5',
    fallback: '5 days',
  },
  day6: {
    messageKey: 'setup.proof.settings.day6',
    fallback: '6 days',
  },
  day7: {
    messageKey: 'setup.proof.settings.day7',
    fallback: '7 days',
  },
  day8: {
    messageKey: 'setup.proof.settings.day8',
    fallback: '8 days',
  },
  day9: {
    messageKey: 'setup.proof.settings.day9',
    fallback: '9 days',
  },
  day10: {
    messageKey: 'setup.proof.settings.day10',
    fallback: '10 days',
  },
};

const deadlineAtRiskLocalizations = {
  hour1: {
    messageKey: 'setup.proof.settings.hour1',
    fallback: '1 hour',
  },
  hour2: {
    messageKey: 'setup.proof.settings.hour2',
    fallback: '2 hours',
  },
  hour3: {
    messageKey: 'setup.proof.settings.hour3',
    fallback: '3 hours',
  },
  hour6: {
    messageKey: 'setup.proof.settings.hour6',
    fallback: '6 hours',
  },
  hour12: {
    messageKey: 'setup.proof.settings.hour12',
    fallback: '12 hours',
  },
  hour24: {
    messageKey: 'setup.proof.settings.hour24',
    fallback: '24 hours',
  },
  hour48: {
    messageKey: 'setup.proof.settings.hour48',
    fallback: '48 hours',
  },
};

export const useDefaultDeadlineOptions = () => {
  const [localizations] = useLocalizations(deadlineOptionLocalizations);

  return [
    {
      label: localizations.none,
      value: 0,
    },
    {
      label: localizations.minutes15,
      value: 1,
    },
    {
      label: localizations.businessday1,
      value: 2,
    },
    {
      label: localizations.businessday2,
      value: 3,
    },
    {
      label: localizations.businessday3,
      value: 4,
    },
    {
      label: localizations.businessday4,
      value: 5,
    },
    {
      label: localizations.businessday5,
      value: 6,
    },
    {
      label: localizations.businessday6,
      value: 7,
    },
    {
      label: localizations.businessday7,
      value: 8,
    },
    {
      label: localizations.businessday8,
      value: 9,
    },
    {
      label: localizations.businessday9,
      value: 10,
    },
    {
      label: localizations.businessday10,
      value: 11,
    },
  ];
};

export const useSelectedDefaultDeadlineOptions = () => {
  const [localizations] = useLocalizations(selectedDeadlineOptionLocalizations);

  return [
    {
      label: localizations.none,
      value: 0,
    },
    {
      label: localizations.minutes15,
      value: 1,
    },
    {
      label: localizations.day1,
      value: 2,
    },
    {
      label: localizations.day2,
      value: 3,
    },
    {
      label: localizations.day3,
      value: 4,
    },
    {
      label: localizations.day4,
      value: 5,
    },
    {
      label: localizations.day5,
      value: 6,
    },
    {
      label: localizations.day6,
      value: 7,
    },
    {
      label: localizations.day7,
      value: 8,
    },
    {
      label: localizations.day8,
      value: 9,
    },
    {
      label: localizations.day9,
      value: 10,
    },
    {
      label: localizations.day10,
      value: 11,
    },
  ];
};

export const useDeadlineAtRiskOptions = () => {
  const [localizations] = useLocalizations(deadlineAtRiskLocalizations);

  return [
    {
      label: localizations.hour1,
      value: 1,
    },
    {
      label: localizations.hour2,
      value: 2,
    },
    {
      label: localizations.hour3,
      value: 3,
    },
    {
      label: localizations.hour6,
      value: 6,
    },
    {
      label: localizations.hour12,
      value: 12,
    },
    {
      label: localizations.hour24,
      value: 24,
    },
    {
      label: localizations.hour48,
      value: 48,
    },
  ];
};
