import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';

const settingsCustomMessageStyles = css`
  width: 100%;

  padding-left: 34px;
  font-size: 12px;

  /* primary.gray(500) */
  color: #595f69;
`;

const SettingsCustomMessage = (props) => {
  return (
    <div data-testid="settings-custom-message" className={settingsCustomMessageStyles}>
      {props.children}
    </div>
  );
};

SettingsCustomMessage.propTypes = {
  children: PropTypes.node,
};

export default SettingsCustomMessage;
