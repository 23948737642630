import React, { useState, useEffect } from 'react';
import { Button, Textarea, primary } from '@phoenix/all';
import { shape, bool, string, number } from 'prop-types';
import HideSmallIcon from 'phoenix-icons/dist/HideSmallIcon.js';
import ViewSmallIcon from 'phoenix-icons/dist/ViewSmallIcon.js';
import DragHandleIcon from 'phoenix-icons/dist/DragHandleIcon.js';
import { useSelector, useDispatch } from 'react-redux';
import { css } from 'emotion';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { updateSettingsValue } from '../redux/settings/settings.actions';

const decisionCardContainerStyles = css`
  position: relative;
  &:hover {
    .icon-button {
      display: flex;
    }
    .handle-icon {
      display: flex;
    }
  }
  .icon-button {
    display: none;
  }
  .handle-icon {
    display: none;
  }
`;

const decisionCardStyles = css`
  border: 1px solid ${primary.gray(400)};
  border-radius: 3px;
  height: 78px;
  position: relative;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

const dragHandleStyles = css`
  position: absolute;
  left: 0px;
  padding: 3px;
  overflow: hidden;
  height: 100%;
  width: 20px;
  align-items: center;
  display: flex;
  svg {
    position: absolute;
    left: -7px;
    box-shadow: none;
  }
`;

const buttonStyle = css`
  width: 100%;
  height: auto;
  overflow-wrap: anywhere;
  display: block;
  overflow: hidden;
  &:hover:enabled {
    cursor: text;
    text-decoration: none;
    outline: 2px solid ${primary.blue()};
  }
  &:disabled {
    color: ${primary.blue()};
  }
`;

const decisionCardHiddenStyles = css`
  ${decisionCardStyles}
  opacity: 0.4;
`;

const inputStyles = css`
  width: 100%;
  max-height: 100%;
  overflow: hidden !important;
  resize: none;
  padding: 0;
  padding-left: 4px;
  margin: 0;
  text-align: center;
`;

const hideShowIconStyles = css`
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 0px;
  padding-top: 4px;
  height: auto;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 100ms;
  animation-delay: 100ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: linear, ease;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const SettingsDecisionInput = ({ decision: { enabled, label, defaultLabel }, isActive }) => {
  const { setNodeRef, listeners, transform, transition, attributes, isDragging } = useSortable({
    id: defaultLabel,
  });

  const [isEditMode, setIsEditMode] = useState(false);
  const [currentLabel, setCurrentLabel] = useState(label);

  const getHandleStyles = () => {
    if (isActive) {
      //  overriding default pointer cursor to match designs
      return css`
        cursor: grabbing !important;
      `;
    }
    return css``;
  };

  const transformationStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const {
    editedData: { decisions },
  } = useSelector((state) => state.settings);
  const dispatch = useDispatch();

  const updateDecisions = (key, value) => {
    const decisionIndex = decisions.findIndex((element) => {
      return element.defaultLabel === defaultLabel;
    });

    const originalDecision = decisions[decisionIndex];
    const newDecisions = [...decisions];

    newDecisions[decisionIndex] = { ...originalDecision, [key]: value };
    dispatch(updateSettingsValue('decisions', newDecisions));
  };

  const handleSaveLabel = (newLabel) => {
    // if the new label is an empty string default back to the original label
    if (newLabel && newLabel.trim() !== '') {
      updateDecisions('label', newLabel);
    } else {
      setCurrentLabel(label);
    }
    setIsEditMode(false);
  };

  const autoFocusAndSelect = () => {
    const input = document.getElementsByClassName(`decision-input${defaultLabel}`)[0];
    if (input) {
      input.focus();
      input.select();
    }
  };

  useEffect(() => {
    setCurrentLabel(label);
  }, [dispatch, label]);

  return (
    <div
      data-testid="decision-input"
      className={decisionCardContainerStyles}
      ref={setNodeRef}
      transform={transform}
      transition={transition}
      style={transformationStyle}
    >
      <div
        className={
          (enabled || isActive) && !isDragging ? decisionCardStyles : decisionCardHiddenStyles
        }
      >
        {!isEditMode && (
          <div className={dragHandleStyles}>
            <DragHandleIcon
              {...attributes}
              {...listeners}
              id={defaultLabel}
              className={`handle-icon ${getHandleStyles()}`}
              data-testid={`${defaultLabel}-drag-handle`}
            />
          </div>
        )}
        {isEditMode && enabled ? (
          <Textarea
            className={`${inputStyles} decision-input${defaultLabel}`}
            onChange={(event) => {
              setCurrentLabel(event?.target?.value);
            }}
            name={label}
            aria={{ 'aria-label': label }}
            value={currentLabel}
            onBlur={() => {
              handleSaveLabel(currentLabel);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleSaveLabel(currentLabel);
              }
            }}
            testID="decision-input-custom-label-input"
          />
        ) : (
          <Button
            text
            disabled={!enabled}
            className={buttonStyle}
            onClick={async () => {
              await setIsEditMode(true);
              autoFocusAndSelect();
            }}
            testID="decision-input-label-button"
          >
            {label}
          </Button>
        )}
      </div>
      {!isEditMode && !isActive && (
        <Button
          text
          className={`${hideShowIconStyles} icon-button`}
          icon={enabled ? HideSmallIcon : ViewSmallIcon}
          onClick={() => {
            updateDecisions('enabled', !enabled);
          }}
          testID="decision-input-enabled-button"
        />
      )}
    </div>
  );
};

SettingsDecisionInput.propTypes = {
  decision: shape({
    label: string.isRequired,
    enabled: bool.isRequired,
    defaultLabel: string.isRequired,
    position: number.isRequired,
  }),
  isActive: bool,
};

SettingsDecisionInput.defaultProps = {
  isActive: false,
};

export default SettingsDecisionInput;
