import React from 'react';
import { SetupHeader } from '@setup/components';
import ProofsExtraLargeHeroIcon from 'phoenix-icons/dist/ProofsExtraLargeHeroIcon.js';
import { Localization } from '@wf-mfe/localize-react';

const SettingsHeader = () => {
  return (
    <div>
      <SetupHeader
        title={<Localization messageKey="proof.settings" fallback="Proof Settings" />}
        section={<Localization messageKey="proofs" fallback="Proofs" />}
        contextSensitiveHelpPageID="csh-content-setup-proof-default-settings"
        icon={<ProofsExtraLargeHeroIcon />}
      />
    </div>
  );
};

export default SettingsHeader;
