import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';

const footerStyles = css`
  height: 65px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  border-top: 1px solid #e0e1e3;
`;

const Footer = (props) => {
  const { children } = props;

  return <div className={footerStyles}>{children}</div>;
};

Footer.propTypes = {
  children: PropTypes.node,
};

export default Footer;
