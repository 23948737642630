import React from 'react';
import axios from 'axios';
import { getCurrentCustomer$ } from '@wf-mfe/auth';
import { firstValueFrom } from 'rxjs';
import { Localization } from '@wf-mfe/localize-react';
import localizedToast from '../../utils/localized-toast.util';
import { proofSettingsActionTypes as types } from './settings.types';
import { logger } from '../../logger';

// Get Settings //
export const getSettingsStart = () => ({
  type: types.GET_PROOF_SETTINGS_START,
});

export const getSettingsSuccess = (proofSettingsData) => ({
  type: types.GET_PROOF_SETTINGS_SUCCESS,
  payload: {
    originalData: proofSettingsData,
    editedData: proofSettingsData,
  },
});

export const getSettingsFailure = (errorMessage) => ({
  type: types.GET_PROOF_SETTINGS_FAILURE,
  payload: { error: errorMessage, showErrorPage: true },
});

export const getSettings = () => async (dispatch) => {
  dispatch(getSettingsStart());
  const currentCustomer = await firstValueFrom(getCurrentCustomer$());

  const config = {
    withCredentials: true,
  };

  return axios
    .get(
      `/proof-account-settings/api/v1/accounts/${currentCustomer.proofAccountID}/settings`,
      config
    )
    .then((response) => {
      dispatch(getSettingsSuccess(response.data.accountSettings));
    })
    .catch((error) => {
      dispatch(getSettingsFailure(error.message));
    });
};

// Update Settings //
export const updateSettingsValue = (key, value) => ({
  type: types.UPDATE_SETTINGS_VALUE,
  payload: { [key]: value },
});

// Save Settings //

export const saveSettingsStart = () => ({
  type: types.SAVE_PROOF_SETTINGS_START,
});

export const saveSettingsSuccess = (proofSettingsData) => ({
  type: types.SAVE_PROOF_SETTINGS_SUCCESS,
  payload: { originalData: proofSettingsData, editedData: proofSettingsData },
});

export const saveSettingsFailure = (errorMessage) => ({
  type: types.SAVE_PROOF_SETTINGS_FAILURE,
  payload: { error: errorMessage },
});

export const saveSettings = () => async (dispatch, getState) => {
  const dataToSend = getState().settings.editedData;
  dispatch(saveSettingsStart());
  const currentCustomer = await firstValueFrom(getCurrentCustomer$());

  const config = {
    withCredentials: true,
  };
  const body = { accountSettings: dataToSend };

  return axios
    .put(
      `/proof-account-settings/api/v1/accounts/${currentCustomer.proofAccountID}/settings`,
      body,
      config
    )
    .then((response) => {
      localizedToast(
        'success',
        <Localization
          messageKey="branding.success.save"
          fallback="Changes have been saved successfully!"
        />,
        {
          closable: true,
        }
      );
      dispatch(saveSettingsSuccess(response.data.accountSettings));
    })
    .catch((error) => {
      logger.error(`Error: Failed to save proof settings: ${error}`);
      localizedToast(
        'error',
        <Localization
          messageKey="proof.settings.save.error.message"
          fallback="We couldn't save your changes. Try again."
        />,
        <Localization
          messageKey="proof.settings.save.error.title"
          fallback="Unable to save changes"
        />
      );
      dispatch(saveSettingsFailure(error.message));
    });
};

// revert changes of editedData to originalData

export const revertChangesToOriginalData = () => ({
  type: types.REVERT_CHANGES_TO_ORIGINAL_DATA,
});
