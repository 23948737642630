import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';

const styles = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Page = (props) => {
  const { children } = props;

  return <div className={styles}>{children}</div>;
};

Page.propTypes = {
  children: PropTypes.node,
};

export default Page;
