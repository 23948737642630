import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Localization } from '@wf-mfe/localize-react';
import { LabelDropdown, Text } from '@phoenix/all';
import { css } from 'emotion';
import { updateSettingsValue } from '../redux/settings/settings.actions';
import { useDefaultDeadlineOptions, useSelectedDefaultDeadlineOptions } from './deadline.hooks';

const defaultDeadlineStyles = css`
  display: flex;
  /* This ensures that the spacing below the default deadline field is 20px */
  margin-bottom: 12px;
`;

const DefaultDeadline = () => {
  const dispatch = useDispatch();
  const {
    editedData: { defaultDeadline },
  } = useSelector((state) => state.settings);

  const dropdownOptions = useDefaultDeadlineOptions();
  const selectedOptions = useSelectedDefaultDeadlineOptions();
  const selectedOption = selectedOptions.find((option) => option.value === defaultDeadline);

  return (
    <Text>
      <span className={defaultDeadlineStyles}>
        <Localization
          messageKey="setup.proof.settings.defaultdeadline.before"
          fallback="Set default deadline to"
        />
        &nbsp;
        <LabelDropdown
          multiple={false}
          open={false}
          options={dropdownOptions}
          value={selectedOption}
          onChange={(selectedItem) =>
            dispatch(updateSettingsValue('defaultDeadline', selectedItem.value))
          }
          testID="default-deadline-label-dropdown"
        />
        &nbsp;
        <Localization
          messageKey="setup.proof.settings.defaultdeadline.after"
          fallback="after a proof is created or a stage is activated"
        />
      </span>
    </Text>
  );
};

export default DefaultDeadline;
