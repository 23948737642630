import React from 'react';
import { bool } from 'prop-types';
import { Button, FormGroup, Text } from '@phoenix/all';
import { Localization } from '@wf-mfe/localize-react';
import { css } from 'emotion';
import { useSelector, useDispatch } from 'react-redux';
import { updateSettingsValue } from '../redux/settings/settings.actions';

const decisionInfoStyles = css`
  display: flex;
  justify-content: space-between;
`;

const buttonStyles = css`
  height: 16px;
`;

const SettingsDecisionsInfo = ({ canRestore }) => {
  const {
    editedData: { decisions },
  } = useSelector((state) => state.settings);
  const dispatch = useDispatch();

  const restoreDecisionDefaults = () => {
    const defaultDecisions = [];
    decisions.forEach((decision) => {
      defaultDecisions.push({
        ...decision,
        label: decision.defaultLabel,
        enabled: true,
        position: decision.defaultPosition,
      });
    });
    dispatch(
      updateSettingsValue(
        'decisions',
        defaultDecisions.sort((a, b) => a.position - b.position)
      )
    );
  };

  return (
    <>
      <div className={decisionInfoStyles}>
        <FormGroup>
          <Localization
            messageKey="setup.proof.settings.decisionlabels"
            fallback="Edit decision labels"
          />
        </FormGroup>
        {canRestore && (
          <Button
            className={buttonStyles}
            text
            onClick={() => restoreDecisionDefaults()}
            testID="decisions-info-restore-button"
          >
            <Localization
              messageKey="setup.proof.settings.decisionrestore"
              fallback="Restore defaults"
            />
          </Button>
        )}
      </div>
      <Text.Small>
        <Localization
          messageKey="setup.proof.settings.decisionexplain"
          fallback="You can rename, reorder, or hide decision options."
        />
      </Text.Small>
    </>
  );
};

SettingsDecisionsInfo.propTypes = {
  canRestore: bool.isRequired,
};
export default SettingsDecisionsInfo;
